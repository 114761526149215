import React from 'react';

const Shop = () => {
    return (
        <div className='min-h-screen pt-4 pl-2'>
            <h1>this shop page</h1>
        </div>
    );
};

export default Shop;