import React from 'react';

const AboutPage = () => {
    
    return (
        <div className='min-h-screen'>
            <div>
                <h3>Egonj is an e-commerce platform whose main aim is to deliver organic products to your hands.</h3>
            </div>
            
        </div>
    );
};

export default AboutPage;