import React from "react";
import Banner from "./Banner/Banner";
import LatestProducts from "./LatestPorducts/LatestProducts";
import TopProducts from "./TopProducts.js/TopProducts";
import TopCategories from "./TopCategories/TopCategories";
import Faq from "./FAQ/Faq";
import OfferBanners from "./OfferBanners/OfferBanners";
import MainFacilities from "../../../components/public/mainFacilities/MainFacilities";
// import Newsletter from "./Newsletter/Newsletter";
// import AvailableAreas from "./AvailableAreas/AvailableAreas";
// import TopSellingAndTrending from "./TopSellingAndTrending/TopSellingAndTrending";

const Home = () => {
  window.scrollTo(0, 0);
  return (
    <div className="px-3">
      <Banner />
      <TopCategories />
      <LatestProducts />
      <TopProducts />
      <OfferBanners />
      <MainFacilities />
      {/* <TopSellingAndTrending />
      <AvailableAreas />
      <Newsletter /> */}
      <Faq />
    </div>
  );
};

export default Home;
