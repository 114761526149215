import React from 'react';

const Contact = () => {
    return (
        <div className='min-h-screen'>
            <h1>this contact page</h1>
        </div>
    );
};

export default Contact;